$breakpoint: 500px;
$bp2: 1200px;
$bp3: 900px;
$bp4: 600px;
$bp5: 500px;
$background: #03101eff;
$footerBackground: #293c50;
$leafColor: #96c926;
@import "page-parts/Team";
@import "page-parts/Projects";
@import "page-parts/Footer";
@import "page-parts/Company";

h1 {
  color: white;
  font-weight: normal;
  font-size: 3.5rem;
  @media (max-width: $bp2) {
    font-size: 2.5rem;
  }
  @media (max-width: $bp3) {
    font-size: 2rem;
  }
  @media (max-width: $bp4) {
    font-size: 2rem;
  }
}

h2 {
  font-weight: normal;
  font-size: 4rem;
  text-align: center;
  @media (max-width: $bp2) {
    font-size: 3rem;
  }
  @media (max-width: $bp3) {
    font-size: 2rem;
  }
  @media (max-width: $bp4) {
    font-size: 2rem;
  }
}

a:link,
a:visited,
a:hover,
a:active,
a {
  color: #ffffffaa;
  font-weight: 600;
}
footer a:hover {
  opacity: 0.9;
}

.App {
  flex-direction: column;
  height: 100%;
  display: flex;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background-color: #03101e /*#1f1f1f*/;
  color: white;
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.cover-page {
  padding-top: 80px;
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $background;
  /*filter: progid: DXImageTransform.Microsoft.gradient(gradientType=1, startColorstr='#003073', endColorstr='#029797');*/
  background-size: 100%;
  /*background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0, #000d1f), color-stop(100%, #003f3f));
  background-image: -webkit-linear-gradient(135deg, #003073, #029797);
  background-image: -moz-linear-gradient(45deg, #003073, #029797);
  background-image: -ms-linear-gradient(45deg, #003073 0, #029797 100%);
  background-image: -o-linear-gradient(45deg, #003073, #029797);*/
  background-image: radial-gradient(ellipse at top right, #094949, $background);
  #background {
    width: 100%;
    flex: 2;
    position: relative;
    #particles-container {
      width: 100%;
      position: absolute;
      top: -50%;
      z-index: 1;
      height: 150%;
    }
  }
  #particles-overlay-gradient {
    background-image: linear-gradient(180deg, #09494900, $background);
    position: absolute;
    height: 30%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .text-container {
    margin: auto;
    width: 100%;
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    padding-left: 10px;
    padding-right: 10px;
    h1 {
      text-align: center;
    }
  }
}

nav {
  background-color: $background;
  width: 100%;
  flex-direction: row;
  display: flex;
  padding-top: 10px;

  .tl-logo {
    width: 50px;
    margin-bottom: 20px;
    align-self: flex-start;
    margin-right: 10px;
    margin-left: 10px;
  }
  .nav-menu {
    display: flex;
    column-gap: 20px;
    margin-top: 15px;
    margin-right: 20px;

    a {
      text-decoration: none;
      color: white;
    }
  }
  .nav-header {
    width: 100%;
    flex-direction: row;
    display: flex;
  }
  .company-name {
    @media (max-width: $bp5) {
      display: none;
    }
  }
}

.sized-font {
  font-size: 6vw;
}
@media screen and (min-width: 417pt) {
  .sized-font {
    font-size: 25pt;
  }
}

.scrollable {
  scroll-behavior: smooth;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
  flex: 1;
  position: relative;
}

.container {
  max-width: 900px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
