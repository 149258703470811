.team {
  .team-member {
    display: flex;
    align-items: center;
  }
  .team-members-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 30px;
  }
  .team-name {
    font-size: 2em;
    margin: 0;
    display: flex;
    align-items: baseline;
  }
  .team-name-main {
    white-space: nowrap;
  }
  .team-cv {
  }
  .team-links {
  }
  .team-details {
    flex: 1;
    text-align: left;
  }
  .team-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .team-image {
    width: 140px;
    height: 140px;
    margin: 20px;
  }
  .team-links a img {
    width: 30px;
    align-items: baseline;
    margin-left: 10px;
  }
  @media (max-width: $breakpoint) {
    .team-member {
      flex-wrap: wrap;
      column-gap: 20px;
    }
    .team-member > * {
      flex-basis: 100%;
    }
    .team-name {
      text-align: center;
      align-items: center;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}
