.projects {
  .product-container h3 {
    text-align: left;
    font-size: 1.8em;
    font-weight: normal;
  }

  .product-img {
    max-width: 450px;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    width: 100%;
  }

  .product-description {
    padding-left: 20px;
    max-width: 100%;
    align-self: stretch;
  }

  .product-text {
    text-align: left;
    font-size: 1.2em;
    line-height: 150%;
  }

  .product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
  }
}
