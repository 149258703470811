.footer-background {
  background-color: $footerBackground;
  width: 100%;
  margin-top: 50px;
}

.footer {
  padding: 30px 0px;
  .footer-text {
    text-align: center;
  }
}
